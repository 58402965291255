import { Request } from '@/utils/request'

const API = {
  login: (form = {}) => {
    return Request.post('login', form)
  },
  loginFulu: (form = {}) => {
    return Request.post('authorization_code', form)
  },
  tableTemplate: (form = {}) => {
    const { pageSize, pageIndex } = form
    return {
      current: pageIndex || 1,
      list: [
        {
          xxa: '测试文本',
          xxb: '测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本',
          xxc: '<i>斜的字</i>',
          xxd: 1,
          xxe: 0,
          xxf: 1,
          xxg: '2021-12-08 17:44:03',
          xxh: '图片地址是：',
          xxi: '@/assets/logo.png',
          xxj: '测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本测试长文本'
        }
      ],
      pageSize: pageSize || 10,
      pageTotal: 70,
      total: 695
    }
  },
  // 系统更细
  SystemLoginCheck: (form = {}) => {
    return Request.get('login/SystemLoginCheck', { params: form })
  },
  // 获取公告分页数据
  GetSystemMessagePageList: (form = {}) => {
    return Request.get('System/GetSystemMessagePageList', { params: form })
  },
  // 获取公告详情
  GetSystemMessageDetail: (form = {}) => {
    return Request.get('System/GetSystemMessageDetail', { params: form })
  },
  // 获取公告开屏列表
  GetSystemMessageIsShowPageList: (form = {}) => {
    return Request.get('System/GetSystemMessageIsShowPageList', { params: form })
  },
  // 设置所有公告已读
  SetAllSystemMessageRead: (form = {}) => {
    return Request.get('System/SetAllSystemMessageRead', { params: form })
  },
  // 消息列表里
  GetSystemAlertPageList: (form = {}) => {
    return Request.get('SystemAlert/GetSystemAlertPageList', { params: form })
  },
  // 轮询获取消息
  GetSystemAlert: (form = {}) => {
    return Request.get('SystemAlert/GetSystemAlert', { params: form })
  },
  // 获取消息带总数
  GetSystemAlertWithSumCount: (form = {}) => {
    return Request.get('SystemAlert/GetSystemAlertWithSumCount', {
      params: form
    })
  },
  // 读取消息
  ReadSystemAlert: (form = {}) => {
    return Request.get('SystemAlert/ReadSystemAlert', { params: form })
  },
  // 全部设置已读消息
  SetAllReadSystemAlert: (form = {}) => {
    return Request.post('SystemAlert/SetAllReadSystemAlert', form)
  },
  // 获取商户认证签约信息
  GetAuthAndSign: (form = {}) => {
    return Request.get('System/GetAuthAndSign', { params: form })
  },
  // 首页信息
  GetIndexData: (form = {}) => {
    return Request.get('System/GetIndexData', { params: form })
  },
  // 获取我的待办
  GetMyToDo: (form = {}) => {
    return Request.get('Home/GetMyToDo', { params: form })
  },
  // 修改密码
  UpdateMerchantUserPwd: (form = {}) => {
    return Request.post('System/UpdateMerchantUserPwd', form)
  },
  // 创客列表
  GetWorkPeoplePageList: (form = {}) => {
    return Request.get('WorkPeople/GetWorkPeoplePageList', { params: form })
  },
  // 创客人员列表详情
  GetWorkPeopleDetail: (form = {}) => {
    return Request.get('WorkPeople/GetWorkPeopleDetail', { params: form })
  },
  // 获取创客明细任务结算分页数据
  GetWorkPeopleTaskPageList: (form = {}) => {
    return Request.get('WorkPeople/GetWorkPeopleTaskPageList', {
      params: form
    })
  },
  GetWorkPeopleTaskDetailPageList: (form = {}) => {
    return Request.get('WorkPeople/GetWorkPeopleTaskDetailPageList', {
      params: form
    })
  },
  // 获取创客明细合同分页数据
  GetWorkPeopleContractPageList: (form = {}) => {
    return Request.get('WorkPeople/GetWorkPeopleContractPageList', {
      params: form
    })
  },
  // 创客列表签约
  WorkPeopleSendSignMsg: (form = {}) => {
    return Request.post('WorkPeople/SendSignMsg', form)
  },
  WorkPeopleMultiSendSignMsg: (form = {}) => {
    return Request.post('WorkPeople/MultiSendSignMsg', form)
  },
  // 下载创客模板
  WorkPeopleDownload: (form = {}) => {
    return Request.post('WorkPeople/download', form, { responseType: 'blob' })
  },
  // 下载成功验收单模板
  DownloadAcceptanceNoteFile: (form = {}) => {
    return Request.post('TaskManage/DownloadAcceptanceNoteFile', form, { responseType: 'blob' })
  },
  // 检查上传人员进度状态
  GetWorkPeopleCheck: (form = {}) => {
    return Request.get('WorkPeople/check', { params: form })
  },
  // 确认上传人员进度状态
  ConfirmWorkPeopleCheck: (form = {}) => {
    return Request.post('WorkPeople/confirm', form)
  },
  // 获得个体户分页数据
  GetIndividualInfoPageList: (form = {}) => {
    return Request.get('IndividualPeople/GetIndividualInfoPageList', { params: form })
  },
  // 获得个体户明细数据
  GetIndividualInfo: (form = {}) => {
    return Request.get('IndividualPeople/GetIndividualInfo', { params: form })
  },
  // 下载个体户Excel模板
  DownloadIndividualPeople: (form = {}) => {
    return Request.post('IndividualPeople/download', form, { responseType: 'blob' })
  },
  // 个体户导入
  ImportIndividualPeople: (form = {}) => {
    return Request.post('IndividualPeople/import', form)
  },
  // 获得任务分页数据审核通过数据
  GetTaskManageApprovePageList: (form = {}) => {
    return Request.get('TaskManage/GetTaskManageApprovePageList', {
      params: form
    })
  },
  // 任务列表
  GetTaskManagePageList: (form = {}) => {
    return Request.get('TaskManage/GetTaskManagePageList', { params: form })
  },
  // 任务详情
  GetTaskManage: (form = {}) => {
    return Request.get('TaskManage/GetTaskManage', { params: form })
  },
  // 编辑任务，id为空即为新增
  AddTaskManage: (form = {}) => {
    return Request.post('TaskManage/AddTaskManage', form)
  },
  // 终止/启动任务，id为空即为新增
  UpdateTaskManageState: (form = {}) => {
    return Request.get('TaskManage/UpdateTaskManageState', { params: form })
  },
  // 完税记录
  GetTaxVoucherAttach: (form = {}) => {
    return Request.post('WorkPlan/GetTaxVoucherAttachList', form)
  },
  // 获取可开票结算单分页数据
  GetWorkplanInfo: (form = {}) => {
    return Request.get('BillApply/GetInvoiceWorkPlanPageList', {
      params: form
    })
  },
  // 获取可开票结算明细分页数据
  GetInvoiceWorkDetailPageList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceWorkDetailPageList', {
      params: form
    })
  },
  // 可开票统计金额
  GetSummaryInvoiceAmount: (form = {}) => {
    return Request.get('BillApply/GetSummaryInvoiceAmount', {
      params: form
    })
  },
  // 全部可开票列表（按结算单）
  GetAllWorkplanInfo: (form = {}) => {
    return Request.get('BillApply/GetInvoiceWorkPlanAllList', { params: form })
  },
  // 全部可开票列表（按结算明细）
  GetInvoiceWorkDetailAllList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceWorkDetailAllList', { params: form })
  },
  // 开票税目
  GetTaxItems: (form = {}) => {
    return Request.get('BillApply/TaxItems', { params: form })
  },
  // 申请开票
  AddBillInfo: (form = {}) => {
    return Request.post('BillApply/AddInvoice', form)
  },
  // 获取税目对应的可开票金额
  GetTaxCanBillList: (form = {}) => {
    return Request.get('BillApply/GetTaxCanBillList', { params: form })
  },
  // 获取开票类型列表
  GetSupplierInvoiceTypeList: (form = {}) => {
    return Request.get('BillApply/GetSupplierInvoiceTypeList', { params: form })
  },
  // 开票记录
  GetBillInfo: (form = {}) => {
    return Request.get('BillApply/GetInvoicePageList', { params: form })
  },
  GetInvoiceDetailPlanPageList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceDetailPlanPageList', {
      params: form
    })
  },
  GetInvoiceDetailWorkDetailPageList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceDetailWorkDetailPageList', {
      params: form
    })
  },
  GetInvoiceDetailReceiptPageList: (form = {}) => {
    return Request.get('BillApply/GetInvoiceDetailReceiptPageList', {
      params: form
    })
  },
  // 取消开票
  CancelBillInfo: (form = {}) => {
    return Request.get('BillApply/BillInfoCancel', { params: form })
  },
  // 开票记录详情
  BillInfoDetail: (form = {}) => {
    return Request.get('BillApply/BillInfoDetail', { params: form })
  },
  // 导出开票记录
  ExportBillInfoData: (form = {}) => {
    return Request.post('BillApply/ExportBillInfoData', form, {
      responseType: 'blob'
    })
  },
  // 撤回开票申请
  RevocationInvoice: (form = {}) => {
    return Request.post('BillApply/RevocationInvoice', form)
  },
  // 邮寄地址
  GetAddressInfo: (form = {}) => {
    return Request.get('AddressManage/AddressInfo', { params: form })
  },
  // 新建邮寄地址
  AddAddressInfo: (form = {}) => {
    return Request.get('AddressManage/AddAddressInfo', { params: form })
  },
  // 编辑邮寄地址
  EditAddressInfo: (form = {}) => {
    return Request.get('AddressManage/UpdateAddressInfo', { params: form })
  },
  // 设置默认邮寄地址
  DefaultAddressInfo: (form = {}) => {
    return Request.get('AddressManage/DefaultAddressInfo', { params: form })
  },
  // 删除邮寄地址
  DeleteAddressInfo: (form = {}) => {
    return Request.get('AddressManage/DeleteAddressInfo', { params: form })
  },
  // 发票抬头
  GetRiseInfo: (form = {}) => {
    return Request.get('RiseManage/RiseInfo', { params: form })
  },
  // 新建发票抬头
  AddRiseInfo: (form = {}) => {
    return Request.get('RiseManage/AddRiseInfo', { params: form })
  },
  // 编辑发票抬头
  EditRiseInfo: (form = {}) => {
    return Request.get('RiseManage/UpdateRiseInfo', { params: form })
  },
  // 设置默认发票抬头
  DefaultRiseInfo: (form = {}) => {
    return Request.get('RiseManage/IsDefault', { params: form })
  },
  // 删除发票抬头
  DeleteRiseInfo: (form = {}) => {
    return Request.get('RiseManage/DeleteRiseInfo', { params: form })
  },
  // 账号信息
  GetMerchantInfo: () => {
    return Request.get('Merchant/GetMerchantInfo')
  },
  // 获取供应商账户余额
  GetProviderBalance: (form = {}) => {
    return Request.get('Finance/GetProviderBalance', { params: form })
  },
  // 余额不足提醒设置
  GetMerchantBalanceWarnSetting: (form = {}) => {
    return Request.post('Merchant/GetMerchantBalanceWarnSetting', form)
  },
  // 余额不足提醒设置
  SaveMerchantBalanceWarnSetting: (form = {}) => {
    return Request.post('Merchant/SaveMerchantBalanceWarnSetting', form)
  },
  // 结算单列表
  GetWorkPlanPageList: (form = {}) => {
    return Request.get('WorkPlan/GetWorkPlanPageList', { params: form })
  },
  // 下载结算单Excel模板
  DownloadWorkPlanTemplateFile: (form = {}) => {
    return Request.post('WorkPlan/DownloadWorkPlanTemplateFile', form, {
      responseType: 'blob'
    })
  },
  // 检查上传结算单进度状态
  CheckUploadWorkPlanState: (form = {}) => {
    return Request.get('WorkPlan/CheckUploadWorkPlanState', { params: form })
  },
  // 确认上传结算单进度状态
  ConfirmSubmitWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/ConfirmSubmitWorkPlan', form)
  },
  // 导出上传结算单
  ExportCheckUploadWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/ExportCheckUploadWorkPlan', form, {
      responseType: 'blob'
    })
  },
  // 获取结算单导入记录分页数据
  GetWorkPlanImportList: (form = {}) => {
    return Request.get('WorkPlan/GetWorkPlanImportList', { params: form })
  },
  // 导出导入校验结果
  ExportImportResult: (form = {}) => {
    return Request.post('WorkPlan/ExportImportResult', form, {
      responseType: 'blob'
    })
  },
  // 结算单发送手机验证码
  WorkPlanSendVerifyCode: (form = {}) => {
    return Request.post('WorkPlan/SendVerifyCode', form)
  },
  // 结算单确认预发放
  PreConfirmGrant: (form = {}) => {
    return Request.post('WorkPlan/PreConfirmGrant', form)
  },
  // 结算单确认发放
  ConfirmGrant: (form = {}) => {
    return Request.post('WorkPlan/ConfirmGrant', form)
  },
  // 确认审核
  StartAuditWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/StartAuditWorkPlan', form)
  },
  // 拒绝发放
  WorkPlanCancel: (form = {}) => {
    return Request.post('WorkPlan/WorkPlanCancel', form)
  },
  // 删除结算单
  WorkPlanDelete: (form = {}) => {
    return Request.post('WorkPlan/WorkPlanDelete', form)
  },
  // 批量确认发放
  BatchConfirmGrant: (form = {}) => {
    return Request.post('WorkPlan/BatchConfirmGrant', form)
  },
  // 批量确认审核
  BatchStartAuditWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/BatchStartAuditWorkPlan', form)
  },
  // 撤销结算单
  StartBackOutWorkPlan: (form = {}) => {
    return Request.post('WorkPlan/StartBackOutWorkPlan', form)
  },
  // 结算明细列表
  GetWorkDetailPageList: (form = {}) => {
    return Request.get('WorkPlan/GetWorkDetailPageList', { params: form })
  },
  // 拒绝发放明细
  WorkDetailCancel: (form = {}) => {
    return Request.post('WorkPlan/WorkDetailCancel', form)
  },
  // 删除结算明细
  WorkDetailDelete: (form = {}) => {
    return Request.post('WorkPlan/WorkDetailDelete', form)
  },
  // 获取结算明细整合数据
  GetWorkDetailSumData: (form = {}) => {
    return Request.get('WorkPlan/GetDetailSumData', { params: form })
  },
  // 导出结算明细
  ExportWorkDetailData: (form = {}) => {
    return Request.post('WorkPlan/ExportDetailData', form, {
      responseType: 'blob',
      timeout: 30000
    })
  },
  // 获取结算回执
  GetReceiptInfo: (form = {}) => {
    return Request.get('WorkPlan/GetReceiptInfo', { params: form })
  },
  // 财务中心
  // 我的账户
  GetMyAccountData: (form = {}) => {
    return Request.get('Finance/GetMyAccountData', { params: form })
  },
  // 提交充值申请
  SubmitRechargeAccount: (form = {}) => {
    return Request.post('Merchant/SubmitRechargeAccount', form)
  },
  // 充值流水
  GetRechargeLogPageList: (form = {}) => {
    return Request.get('Finance/GetRechargeLogPageList', { params: form })
  },
  // 累计充值金额
  GetGetRechargeLogTotalAmount: (form = {}) => {
    return Request.get('Finance/GetGetRechargeLogTotalAmount', {
      params: form
    })
  },
  // 退汇记录
  GetFinanceReturnLogPageList: (form = {}) => {
    return Request.get('Finance/GetFinanceReturnLogPageList', { params: form })
  },
  // 商户未开票的退汇订单
  GetReturnWorkDetailNotBill: (form = {}) => {
    return Request.get('BillApply/GetReturnWorkDetialNotBill', { params: form })
  },
  // 指定发票的退汇订单
  GetReturnWorkDetailByInvoiceId: (form = {}) => {
    return Request.get('BillApply/GetReturnWorkDetialByInvoiceId', { params: form })
  },
  // 支出流水
  GetDisburseLogPageList: (form = {}) => {
    return Request.get('Finance/GetDisburseLogPageList', { params: form })
  },
  // 电子回单压缩包列表
  GetReceiptZipPageList: (form = {}) => {
    return Request.get('Finance/GetReceiptZipPageList', { params: form })
  },
  // 生成电子回单压缩包
  BuildReceiptZip: (form = {}) => {
    return Request.post('Finance/BuildReceiptZip', form)
  },
  // 判断结算单是由可以生成电子回单
  IsExistReceiptByWorkPlan: (form = {}) => {
    return Request.post('Finance/IsExistReceiptByWorkPlan', form)
  },
  // 结算单生成电子回单压缩包
  BuildReceiptZipByWorkPlan: (form = {}) => {
    return Request.post('Finance/BuildReceiptZipByWorkPlan', form)
  },
  // 累计支出金额
  GetDisburseLogTotalAmount: (form = {}) => {
    return Request.get('Finance/GetDisburseLogTotalAmount', { params: form })
  },
  // 获得商户API详细信息
  GetMerchantApi: (form = {}) => {
    return Request.get('MerchantAPI/GetMerchantApi', { params: form })
  },
  // 编辑商户API信息
  EditMerchantApi: (form = {}) => {
    return Request.get('MerchantAPI/EditMerchantApi', { params: form })
  },
  // 密钥发送手机验证码
  MerchantAPISendVerifyCode: (form = {}) => {
    return Request.post('MerchantAPI/SendVerifyCode', form)
  },
  // 查看密钥发送验证码
  ViewKeySendSms: (form = {}) => {
    return Request.post('Sms/ViewKeySendSms', form)
  },
  // 查看密钥
  CheckSecretKey: (form = {}) => {
    return Request.post('MerchantAPI/CheckSecretKey', form)
  },
  // 导出创客列表
  ExportWorkPeopleList: (form = {}) => {
    return Request.post('WorkPeople/ExportWorkPeopleList', form, {
      responseType: 'blob'
    })
  },
  // 白名单列表
  GetWhitelist: (form = {}) => {
    return Request.get('/System/GetSystemWhitePageList', { params: form })
  },
  // 白名单详情
  GetWhitelistDetail: (form = {}) => {
    return Request.get('/System/GetSystemWhiteInfo', { params: form })
  },
  // 白名单详情（编辑）
  GetSystemWhiteInfoByEdit: (form = {}) => {
    return Request.get('/System/GetSystemWhiteInfoByEdit', { params: form })
  },
  // 黑名单列表
  GetBlacklist: (form = {}) => {
    return Request.get('/System/GetSystemBlackPageList', { params: form })
  },
  // 交付物列表
  GetRegulateList: (form = {}) => {
    return Request.get('/TaskManage/GetTaskDeliverableList', { params: form })
  },
  // 交付物修改
  PutRegulateItem: (form = {}) => {
    return Request.post('/TaskManage/UpdateTaskDeliverable', form)
  },
  // 交付物新增
  PushAddRegulateItem: (form = {}) => {
    return Request.post('/TaskManage/AddTaskDeliverable', form)
  },
  // 交付物模板下载
  DownloadTaskDeliverableFile: (form = {}) => {
    return Request.post('/TaskManage/DownloadTaskDeliverableFile', form, {
      responseType: 'blob'
    })
  },
  // 业务确认单列表
  GetConfirmReceiptsPageList: (form = {}) => {
    return Request.get('/Merchant/GetConfirmReceiptsPageList', {
      params: form
    })
  },
  // 业务确认单word下载前校验
  ConfirmReceiptsTemplateCheck: (form = {}) => {
    return Request.post('/Merchant/ConfirmReceiptsTemplateCheck', form)
  },
  // 业务确认单word下载
  DownloadConfirmReceiptsFile: (form = {}) => {
    return Request.post('/Merchant/DownloadConfirmReceiptsFile', form, {
      responseType: 'blob'
    })
  },
  // 业务确认单上传
  UploadConfirmReceipts: (form = {}) => {
    return Request.post('/Merchant/UploadConfirmReceipts', form)
  },
  // 获取任务编号列表
  GetTaskManageList: (form = {}) => {
    return Request.get('/TaskManage/GetTaskManageList', { params: form })
  },
  // 获取税源地编号名称列表
  GetSupplierInfoCodeName: (form = {}) => {
    return Request.post('/Merchant/GetSupplierInfoCodeName', form)
  },
  // 下载业务确认单列表项附件
  GetConfirmReceiptsFileUrl: (form = {}) => {
    return Request.get('/Merchant/GetConfirmReceiptsFileUrl', { params: form })
  },
  // 增加创客
  AddWorkPeople: (form = {}) => {
    return Request.post('/WorkPeople/Add', form)
  },
  // 编辑创客
  UpdateWorkPeople: (form = {}) => {
    return Request.post('/WorkPeople/Update', form)
  },
  // 获取充值后金额
  GetGetRechargeLogTotalAmount: (form = {}) => {
    return Request.get('/Finance/GetGetRechargeLogTotalAmount', {
      params: form
    })
  },
  // 获取支出累计金额
  GetDisburseLogTotalAmount: (form = {}) => {
    return Request.get('/Finance/GetDisburseLogTotalAmount', { params: form })
  },
  // 获取完税凭证分页数据
  GetTaxPaymentReceiptsList: (form = {}) => {
    return Request.get('/BillApply/GetTaxPaymentReceiptsList', {
      params: form
    })
  },
  // 发送获取创客敏感数据短信验证码
  SendWpVerifySMS: (form = {}) => {
    return Request.post('/WorkPeople/VerifySendSMS', form)
  },
  // 发送获取创客敏感数据短信验证码
  EditViewPeopleSendSms: (form = {}) => {
    return Request.post('/Sms/EditViewPeopleSendSms', form)
  },
  // 通过短信验证码获取创客敏感数据
  GetWpVerify: (form = {}) => {
    return Request.get('/WorkPeople/GetWorkPeopleDetailByVerifyCode', {
      params: form
    })
  },
  // 更换旧手机号发送短信验证
  ChangeMobileSendSms: (form = {}) => {
    return Request.post('/Sms/ChangeMobileSendSms', form)
  },
  // 验证就手机号短信验证码
  OldMobileVerifySendSms: (code = '') => {
    return Request.post(`/Merchant/OldMobileVerifySendSms?VCode=${code}`)
  },
  // 设置手机号发送短信验证码
  SetMobileSendSms: (form = {}) => {
    return Request.post('/Sms/SetMobileSendSms', form)
  },
  // 根据短信验证码设置新手机号
  SetMobileVerifySendSms: (form = {}) => {
    return Request.post('/Merchant/SetMobileVerifySendSms', form)
  },
  // 商户子账号列表
  GetMerchantUserPageList: (form = {}) => {
    return Request.get('/System/GetMerchantUserPageList', { params: form })
  },
  // 商户角色下拉框数据源
  GetMerchantSystemRoleSelectList: (form = {}) => {
    return Request.get('/System/GetMerchantSystemRoleSelectList', { params: form })
  },
  // 添加子账号
  AddMerchantChildUser: (form = {}) => {
    return Request.post('/System/AddMerchantChildUser', form)
  },
  // 修改子账号
  EditMerchantChildUser: (form = {}) => {
    return Request.post('/System/EditMerchantChildUser', form)
  },
  // 删除子账号
  DeleteMerchantChildUser: (form = {}) => {
    return Request.post('/System/DeleteMerchantChildUser', form)
  },
  // 启用禁用子账号
  DisabledMerchantChildUser: (form = {}) => {
    return Request.post('/System/DisabledMerchantChildUser', form)
  },
  // 上传交付物
  UploadTaskDeliverable: (form = {}) => {
    return Request.post('/TaskManage/UploadTaskDeliverable', form)
  },
  // 查询可提现余额
  GetAllAccountBalance: (form = {}) => {
    return Request.post('/Finance/GetAllAccountBalance', form)
  },
  // 提现表单
  MerchantAccountWithdrawApply: (form = {}) => {
    return Request.post('/Finance/MerchantAccountWithdrawApply', form)
  },
  // 过期账户提现表单
  MerchantExpiryAccountWithdrawApply: (form = {}) => {
    return Request.post('/Finance/MerchantExpiryAccountWithdrawApply', form)
  },
  // 发送验证码
  SendWithdrawVerifyCode: (form = {}) => {
    return Request.post('/Finance/SendWithdrawVerifyCode', form)
  },
  // 提现发送验证码
  WithdrawSendSms: (form = {}) => {
    return Request.post('/Sms/WithdrawSendSms', form)
  },
  // 添加白名单
  AddSystemWhiteInfo: (form = {}) => {
    return Request.post('/System/AddSystemWhiteInfo', form)
  },
  // 重新提交白名单
  ReSubmitSystemWhiteInfo: (form = {}) => {
    return Request.post('/System/ReSubmitSystemWhiteInfo', form)
  },
  // 编辑白名单
  EditSystemWhitInfo: (form = {}) => {
    return Request.post('/System/EditSystemWhitInfo', form)
  },
  // 删除白名单
  DeleteSystemWhiteInfo: (form = {}) => {
    return Request.post('/System/DeleteSystemWhiteInfo', form)
  },
  // 提交白名单
  SubmitSystemWhiteInfo: (form = {}) => {
    return Request.post('/System/SubmitSystemWhiteInfo', form)
  },
  // 撤回白名单
  RevocationSystemWhiteInfo: (form = {}) => {
    return Request.post('/System/RevocationSystemWhiteInfo', form)
  },
  // 导出提现记录
  ExportWithdrawData: (form = {}) => {
    return Request.post('/Finance/ExportWithdrawData', form, {
      responseType: 'blob'
    })
  },
  // 我的协议列表
  GetAgreementList: (form = {}) => {
    return Request.get('/System/GetAgreementList', { params: form })
  },
  // 获取提现记录分页信息
  GetWithdrawLogPageList: (form = {}) => {
    return Request.get('/Finance/GetWithdrawLogPageList', { params: form })
  },
  //
  GetWithdrawLogInfo: (form = {}) => {
    return Request.get('/Finance/GetWithdrawLogInfo', { params: form })
  },
  // 获取交付物列表
  GetOrdinaryTaskDeliverableList: (form = {}) => {
    return Request.get('/TaskManage/GetOrdinaryTaskDeliverableList', { params: form })
  },
  // 获取交付物详情接口
  GetTaskDeliverable: (form = {}) => {
    return Request.get('/TaskManage/GetTaskDeliverable', { params: form })
  },
  //交付物审核
  AuditTaskDeliverable: (form = {}) => {
    return Request.post('/TaskManage/AuditTaskDeliverable', form)
  },
  // 接单列表
  GetTaskManageReceiveList: (form = {}) => {
    return Request.get('/TaskManage/GetTaskManageReceiveList', { params: form })
  },
  // 接单详情接口
  GetTaskManageReceiveInfo: (form = {}) => {
    return Request.get('/TaskManage/GetTaskManageReceiveInfo', { params: form })
  },
  //接单审核
  UpdateTaskManageReceiveState: (form = {}) => {
    return Request.get('/TaskManage/UpdateTaskManageReceiveState', { params: form })
  },
  //接单完成
  FinishTaskManageReceive: (form = {}) => {
    return Request.get('/TaskManage/FinishTaskManageReceive', { params: form })
  },
  //展示任务
  TaskManageHideOrShow: (form = {}) => {
    return Request.get('/TaskManage/TaskManageHideOrShow', { params: form })
  },
  //业务确认单详情
  GetConfirmReceipts: (form = {}) => {
    return Request.get('/Merchant/GetConfirmReceipts', { params: form })
  },
  GetFinanceFundPageList: (form = {}) => {
    return Request.get('Finance/GetFinanceFundPageList', { params: form })
  },
  // 资金流水统计
  GetFinanceFundTypeSummary: (form = {}) => {
    return Request.get('Finance/GetFinanceFundTypeSummary', { params: form })
  },
  // 导出资金流水数据
  ExportFinanceFundData: (form = {}) => {
    return Request.post('Finance/ExportFinanceFundData', form, { responseType: 'blob' })
  },
  //任务编辑
  EditTaskManage: (form = {}, id) => {
    return Request.post(`/TaskManage/EditTaskManage/${id}`, form)
  },
  // 获取小程序签约地址
  GetWechatSignUrl: (form = {}) => {
    return Request.post('Merchant/GetWechatSignUrl', form, { responseType: 'arraybuffer' })
  },
  //抵扣金明细
  getDeductionTardePageList: (form = {}) => {
    return Request.get('/Deduction/getDeductionTardePageList', { params: form })
  },
  //抵扣金明细列表导出
  DeductionTardePageListExport: (form = {}) => {
    return Request.post('Deduction/DeductionTardePageListExport', form, { responseType: 'blob' })
  },
  //抵扣金明细统计
  getDeductionTardePageListStatistics: (form = {}) => {
    return Request.get('/Deduction/getDeductionTardePageListStatistics', { params: form })
  },
  //未通过创客导出
  exportWorkPeople: (form = {}) => {
    return Request.post('WorkPeople/check/export', form, { responseType: 'blob' })
  },
  // 验证是否可发放
  CheckConfirmGrantTime: (form = {}) => {
    return Request.post('WorkPlan/CheckConfirmGrantTime', form)
  },
  // 发票单个下载
  DownLoadInvoiceById: (form = {}) => {
    return Request.post('BillApply/DownLoadInvoiceById', form)
  },
  // 发票批量下载
  DownLoadInvoicePage: (form = {}) => {
    return Request.post('BillApply/DownLoadInvoicePage', form)
  },
  // 获取业务确认单分页列表
  getConfirmList: (form = {}) => {
    return Request.get('Risk/GetConfirmReceiptsPageList', { params: form })
  },
  //获取业务确认单详细信息
  getConfirmInfo: (form = {}) => {
    return Request.get('Risk/GetConfirmReceiptsInfo', { params: form })
  },
  // 确认业务确认单
  confirmReceiptsOrder: (form = {}) => {
    return Request.post('Risk/ConfirmReceipts', form)
  },
  // 重新确认业务确认单
  anewconfirmReceiptsOrder: (form = {}) => {
    return Request.post('Risk/AfreshConfirmReceipts', form)
  },
  // 下载业务确认单Word模板
  downloadWordTemplate: (form = {}) => {
    return Request.post('Risk/DownloadConfirmReceiptsFile', form, { responseType: 'blob' })
  },
  // 下载退汇记录
  ExportFinanceReturnLog: (form = {}) => {
    return Request.post('Finance/ExportFinanceReturnLog', form, { responseType: 'blob' })
  },
  // 创客导出
  ExportWorkPeopleList: (form = {}) => {
    return Request.post('WorkPeople/ExportWorkPeopleList', form, { responseType: 'blob' })
  }
}
export default API
