<template>
  <div class="table-wrap">
    <!-- 标题框 -->
    <div class="title-wrap" v-if="!hideTitle">
      <div class="title">
        <h2>{{ title || $route.meta.title }}</h2>
        <p v-if="titleDesc">{{ titleDesc }}</p>
      </div>
      <div class="btns">
        <el-button v-if="!noRefresh" type="text" icon="el-icon-self-refresh-line" @click="refreshTable">刷新</el-button>
        <el-button v-if="hasMore" type="text" icon="el-icon-self-Sun-calculation" @click="switchSearch">高级筛选</el-button>
      </div>
    </div>
    <!--搜索框的自定义内容-->
    <slot name="ext-search"></slot>
    <!-- 搜索框 -->
    <div class="search-wrap" v-if="!noSearch && searchList.length">
      <!-- <div class="search-content"> -->
      <el-form :inline="true" :model="searchForm" label-suffix="：" class="search-form" @submit.native.prevent>
        <!-- 参数型搜索项组件 -->
        <template v-for="(item, index) in searchList">
          <ComponentSearchItem
            :key="index"
            v-if="!item.more || searchMore"
            :model="searchForm"
            :value="item.prop"
            v-bind="item"
            @input="updateModelData(item.prop, $event)"
          />
        </template>
        <el-form-item class="btn-item has-divider">
          <el-button plain icon="el-icon-self-Reset" @click="resetTable">重置</el-button>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button type="primary" icon="el-icon-self-xiaosuo" @click="searchTable">搜索</el-button>
        </el-form-item>
      </el-form>
      <!-- </div> -->
      <!-- 搜索按钮 -->
      <!-- <div class="search-btns">
        <el-button plain icon="el-icon-self-Reset" @click="resetTable">重置</el-button>
        <el-button type="primary" icon="el-icon-self-xiaosuo" @click="searchTable">搜索</el-button>
      </div> -->
    </div>
    <!-- 自定义的附加表格 -->
    <slot name="ext-table"></slot>
    <!-- 表格相关按钮 -->
    <div class="btnline">
      <el-form inline>
        <!--按钮列的自定义表格内容-->
        <slot name="btnline-item-left"></slot>
      </el-form>
      <el-form inline>
        <!--按钮列的自定义表格内容-->
        <slot name="btnline-item"></slot>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table ref="xTable" :data="datas" v-bind="tableCfg" v-on="$listeners" v-loading="loading">
        <!-- 多选框 -->
        <el-table-column v-if="selection" type="selection" align="center" width="55" fixed v-bind="selConfig"></el-table-column>
        <!-- 序号列 -->
        <el-table-column v-if="seq" type="index" :label="seqLabel" align="center" :width="seqWidth" fixed> </el-table-column>
        <!-- 参数型表格项组件 -->
        <template v-for="(item, idx) in labels">
          <!-- slot项 -->
          <slot v-if="item.cmp == 'slot'" :name="item.slotName"></slot>
          <!-- 预设项 -->
          <ComponentTableColumn v-else v-bind="item" v-on="$listeners" />
        </template>
        <!--后置的自定义表格内容-->
        <slot name="table-item"></slot>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="pagination" v-if="!noPage">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizeOpts"
        :page-size.sync="pageSize"
        :current-page.sync="currentPage"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 表格基础配置项
const DefaultTableCfg = {
  border: true
}
import ComponentTableColumn from './column'
import ComponentSearchItem from './searchItem'
import { excludeEmpty, isArray, deepClone } from '@/utils'
export default {
  name: 'ComponentTable',
  components: { ComponentTableColumn, ComponentSearchItem },
  props: {
    // 标题，默认为路由title
    title: String,
    // 标题描述
    titleDesc: String,
    // 隐藏标题
    hideTitle: false,
    // 数据头列表
    labels: {
      type: Array,
      required: true
    },
    // api数据名
    apiName: {
      type: String
    },
    // 非必填项，有默认值
    // 向下一层的表格数据字段
    resChildName: String,
    // 选择
    selection: {
      type: Boolean,
      default: false
    },
    // 序号部分
    // 是否有序号
    seq: {
      type: Boolean,
      default: true
    },
    // 序号标示，默认#
    seqLabel: {
      type: String,
      default: '序号'
    },
    // 序号格子宽度，默认80
    seqWidth: {
      type: Number,
      default: 65
    },
    // 手动loading
    handLoading: {
      type: Boolean,
      default: false
    },
    // 分页部分
    // 取消分页
    noPage: {
      type: Boolean,
      default: false
    },
    // 搜索部分
    // 取消搜索
    noSearch: {
      type: Boolean,
      default: false
    },
    searchList: {
      type: Array,
      default: () => {
        return []
      }
    },
    noRefresh: {
      type: Boolean,
      default: false
    },
    // 搜索的附加值
    searchExt: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 搜索初始化
    initSearch: {
      type: Object
    },
    // 表格其他配置项
    tableConfig: Object,
    // 表格选框的设置
    selConfig: Object,
    // 禁止首次加载
    disInitLoad: false,
    // 表格
    tableMinHeight: String,
    // 是否是纯前端分页数据
    isFrontEndApi: {
      type: Boolean,
      default: false
    },
    // 纯前端分页数据所有数据
    allListData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      loading: true,
      // 搜索参数
      searchForm: {},
      searchMore: false,
      // 表格参数
      // 数据列表
      datas: [],
      // 分页参数
      currentPage: 1,
      total: 1,
      pageSize: 10,
      pageSizeOpts: [10, 20, 50, 100, 200],
      getSearchWidthEvent: false
    }
  },
  computed: {
    // 序号标序
    seqIndex() {
      return this.pageSize * (this.currentPage - 1) + 1
    },
    // 表格配置项格式化
    tableCfg() {
      return Object.assign({}, DefaultTableCfg, this.tableConfig)
    },
    // 是否有高级搜索
    hasMore() {
      return !!this.searchList.find((item) => {
        return item.more
      })
    }
  },
  watch: {
    searchList(v) {
      if (!this.getSearchWidthEvent) {
        this.getSearchWidth(v)
      }
    }
  },
  mounted() {
    if (this.initSearch) this.searchForm = deepClone(this.initSearch)
    // 首次加载数据
    if (!this.disInitLoad) this.getTableList()
    else this.loading = false

    this.getSearchWidth(this.searchList)
  },
  methods: {
    isArray,
    closeLoading() {
      this.loading = false
    },
    // 获取表格列表 reset重返第一页
    async getTableList(reset = false) {
      const params = this.getParams(reset)
      this.$emit('get', params)
      this.loading = true
      let res = null,
        response = null

      if (this.apiName) {
        // 请求后端接口数据
        response = await this.$api[this.apiName](params)

        res = response
        if (this.resChildName) res = response[this.resChildName]
      } else if (this.isFrontEndApi) {
        // 纯前端分页数据
        let list = []

        if (this.allListData && this.allListData.length) {
          list = this.getFrontEndList()

          if (!(list && list.length)) {
            this.currentPage = this.currentPage == 1 ? 1 : this.currentPage - 1
            list = this.getFrontEndList()
          }
        }

        res = {
          current: this.currentPage,
          list,
          pageSize: this.pageSize,
          total: this.allListData && this.allListData.length
        }
      }
      if (!res) {
        this.datas = []
        this.updatePage({
          current_page: 1,
          total: 0
        })
      } else {
        const { current: current_page, total, list: data } = res
        this.updatePage({
          current_page,
          total
        })
        this.datas = data
      }
      if (!this.handLoading) this.loading = false
      this.$emit('geted', response, params)
    },
    getFrontEndList() {
      return this.allListData.filter((item, index) => {
        if (index < this.currentPage * this.pageSize && index >= (this.currentPage - 1) * this.pageSize) {
          return item
        }
      })
    },
    // 获取当前搜索参数
    getParams(reset = false) {
      const searchForm = deepClone(this.searchForm)
      // 清除more
      if (!this.searchMore) {
        this.searchList.forEach((item) => {
          if (item.more) {
            if (isArray(item.prop)) {
              searchForm[item.prop[0]] = undefined
              searchForm[item.prop[1]] = undefined
            } else {
              searchForm[item.prop] = undefined
            }
          }
        })
      }
      const params = excludeEmpty(Object.assign({ pageIndex: reset ? 1 : this.currentPage, pageSize: this.pageSize }, searchForm, this.searchExt))
      return params
    },
    // 改变当前页码状态
    updatePage(obj) {
      this.currentPage = parseInt(obj.current_page) || 1
      this.total = parseInt(obj.total)
    },
    // 页码尺寸发生变化
    handleSizeChange() {
      this.getTableList()
    },
    // 当前页发生变化
    handleCurrentChange() {
      this.getTableList()
    },
    // 切换搜索
    switchSearch() {
      this.searchMore = !this.searchMore
    },
    // 表单项更新
    updateModelData(key, val) {
      if (isArray(key) && isArray(val)) {
        for (let i in key) {
          this.$set(this.searchForm, key[i], val[i])
        }
      } else {
        this.$set(this.searchForm, key, val)
      }
    },
    // 重置
    async resetTable() {
      this.$emit('reset')
      if (this.initSearch) this.searchForm = deepClone(this.initSearch)
      else this.searchForm = {}
      await this.getTableList(true)
      this.$emit('reseted')
    },
    // 刷新
    async refreshTable() {
      this.$emit('refresh')
      await this.getTableList()
      this.$emit('refreshed')
    },
    // 查询
    async searchTable() {
      this.$emit('search')
      await this.getTableList(true)
      this.$emit('searched')
    },
    // 获取当前datas
    getListDatas() {
      return this.datas
    },
    // 检索项默认展示一行，超出隐藏显示高级搜索
    getSearchWidth(v) {
      if (v && v.length) {
        this.getSearchWidthEvent = true
        this.$nextTick(() => {
          const wrap = document.querySelector('.search-wrap'),
            itemDomArr = wrap.getElementsByClassName('search-item'),
            btnDomArr = wrap.getElementsByClassName('btn-item')

          if (wrap && itemDomArr && itemDomArr.length && btnDomArr && btnDomArr.length) {
            const wrapWidth = wrap.clientWidth,
              itemArr = Array.from(itemDomArr),
              btnArr = Array.from(btnDomArr),
              itemWidthArr = itemArr.map((item) => item.clientWidth),
              btnWidthArr = btnArr.map((item) => item.clientWidth),
              itemSumWidth =
                itemWidthArr.reduce((prev, cur) => {
                  return prev + cur
                }, 0) +
                itemWidthArr.length * 16,
              btnSumWidth =
                btnWidthArr.reduce((prev, cur) => {
                  return prev + cur
                }, 0) +
                12 +
                12

            if (itemSumWidth > wrapWidth - btnSumWidth) {
              // 检索项宽度超过一行
              let sum = 0,
                moreStartIndex = itemWidthArr.findIndex((item, index) => {
                  sum += item + 16
                  if (sum > wrapWidth - btnSumWidth) {
                    return index
                  }
                }, 1)

              this.searchList.forEach((e, i) => {
                if (moreStartIndex > 0 && i >= moreStartIndex) {
                  this.$set(e, 'more', true)
                }
              })
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';
.title-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .title {
    h2 {
      font-size: 16px;
      line-height: 32px;
      font-weight: bold;
      margin: 0;
    }
    p {
      font-size: 14px;
      color: #86909c;
      line-height: 20px;
      margin: 0;
    }
  }
  .btns {
    .el-button--text {
      color: #4e5969;
      padding: 8px 12px 8px 9px;
      border: 0;
      &:hover {
        color: $--color-primary;
      }
    }
  }
}

.search {
  &-wrap {
    border-bottom: 1px solid #f2f3f5;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  &-form {
    margin-bottom: -18px;

    .el-form-item {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
    .btn-item {
      margin-right: 12px;
      &.has-divider {
        border-left: 1px solid #e5e6eb;
        padding-left: 12px;
      }
    }
  }

  &-btns {
    // flex-shrink: 0;
    display: inline-block;
    border-left: 1px solid #e5e6eb;
    padding-left: 16px;
    margin-left: 16px;
  }
}

.pagination {
  margin-top: 16px;
  text-align: right;
  ::v-deep .el-pagination__total {
    float: left;
  }
}

.btnline {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ::v-deep .main-btn-icon {
    margin: 0 -1px 0 -4px;
    font-size: 16px;
  }
}
</style>
