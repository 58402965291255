import store from '@/store'

// 类型判断
function isType(typeStr) {
  typeStr = typeStr.toLowerCase()
  return function (obj) {
    return Object.prototype.toString.call(obj).toLowerCase() === `[object ${typeStr}]`
  }
}
export const isDate = isType('date')
export const isObject = isType('object')
export const isArray = isType('array')
export const isNumber = isType('number')
export const isNull = (val) => val == null
export const isEmpty = (v) => {
  const emptyObj = isObject(v) && !Object.keys(v).length
  return v == null || emptyObj || (typeof v === 'string' && !v)
}

// 获取地址栏信息
export const getUrlParams = (url) => {
  const params = url.split('?')[1]
  const reg = /&?([^&]+)/g
  let res = null
  const map = Object.create(null)
  while ((res = reg.exec(params))) {
    const paramEntry = res[1].split('=')
    map[paramEntry[0]] = paramEntry[1]
  }
  return map
}

// 深拷贝
export const deepClone = (target) => {
  if (!(target instanceof Object)) return {}
  return JSON.parse(JSON.stringify(target))
}
// 深拷贝2代
export const cloneDeep = (obj = {}) => {
  let newObj = null
  if (typeof obj == 'object' && obj != null) {
    newObj = newObj instanceof Array ? [] : {}
    for (let i in obj) {
      newObj[i] = cloneDeep(newObj[i])
    }
  } else {
    newObj = obj
  }
  return newObj
}

// 空处理
export function excludeEmpty(obj) {
  return Object.entries(obj).reduce((map, [k, v]) => {
    if (isEmpty(v)) return map
    map[k] = isObject(v) ? excludeEmpty(v) : v
    return map
  }, {})
}

// 判断权限
export function hasAccess(ruleName) {
  // return true
  if (!ruleName) return true
  // 批量下载回单
  if (ruleName == '350-050')
    return store.state.user.route.some((item) => item == 'batchDownReceipt') && store.state.user.route.some((item) => item == ruleName)
  return store.state.user.route.some((item) => item == ruleName)
}

// 下载blob流Excel文件
export function downloadExcel(blob, filename) {
  const fr = new FileReader()
  fr.readAsDataURL(blob)
  fr.onload = function (e) {
    var exportLink = document.createElement('a')
    exportLink.style = 'visibility: hidden'
    exportLink.download = filename + '.xlsx'
    exportLink.href = e.target.result
    document.body.appendChild(exportLink)
    exportLink.click()
    document.body.removeChild(exportLink)
  }
}
// 下载blob流Excel文件
export function downloadDocx(blob, filename) {
  const fr = new FileReader()
  fr.readAsDataURL(blob)
  fr.onload = function (e) {
    var exportLink = document.createElement('a')
    exportLink.style = 'visibility: hidden'
    exportLink.download = filename + '.docx'
    exportLink.href = e.target.result
    document.body.appendChild(exportLink)
    exportLink.click()
    document.body.removeChild(exportLink)
  }
}

// 下载链接换头
export function downloadUrl(url) {
  window.open(url.replace(/^http:/, document.location.protocol))
}

// 下载链接换头换名字
export function downloadUrlName(url, name) {
  const x = new XMLHttpRequest()
  x.open('GET', url.replace(/^http:/, document.location.protocol), true)
  x.responseType = 'blob'
  x.onload = function (e) {
    var url = window.URL.createObjectURL(x.response)
    var a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()
  }
  x.send()
}
// 银行卡号脱敏
export function desensitizeCardNumber(cardNumber) {
  const length = cardNumber.length
  if (length <= 10) {
    return cardNumber
  }
  const firstTwo = cardNumber.substring(0, 6)
  const lastFour = cardNumber.substring(length - 4)
  const middle = '*'.repeat(length - 6 - 4)
  return firstTwo + middle + lastFour
}

// 函数节流
export function throttle(func, wait) {
  let lastTime = 0
  return function (...args) {
    let now = +new Date()
    if (now - lastTime > wait) {
      lastTime = now
      func.apply(this, args)
    }
  }
}
// 函数防抖
export function debounce(func, wait) {
  var timeout
  return function () {
    var context = this
    var args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      func.apply(context, args)
    }, wait)
  }
}
export function getDay(day) {
  var today = new Date()
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetday_milliseconds) //注意，这行是关键代码
  var tYear = today.getFullYear()
  var tMonth = today.getMonth()
  var tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tYear + '-' + tMonth + '-' + tDate
}
export function doHandleMonth(month) {
  var m = month
  console.log(m)
  if (month.toString().length == 1) {
    m = '0' + month
  }
  return m
}
export function timestampToTime(timestamp, type) {
  var date = new Date(parseInt(timestamp) * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear()
  var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  let zm = date.getMonth() + 1
  let zd = date.getDate()
  var M2 = date.getMonth() + 1
  let day = date.getDay()
  let arr = ['日', '一', '二', '三', '四', '五', '六']

  switch (type) {
    case 'hm':
      return h + ':' + m
      break
    case 'hms':
      return h + ':' + m + ':' + s
      break
    case 'YMD':
      return Y + '.' + M + '.' + D
    case 'YMDHM':
      return Y + '.' + M + '.' + D + ' ' + h + ':' + m
    case 'md':
      return zm + '.' + zd
    case 'YM':
      return Y + '年' + M2 + '月'
    case 'Y-M-D':
      return Y + '-' + M + '-' + D
    case 'day':
      return arr[day]
    default:
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      break
  }
}
