<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '开票记录详情查看'
    }"
  >
    <DetailTabs :tabs="tabs" :active.sync="tabName"></DetailTabs>
    <div v-show="tabName == 'detail'">
      <el-descriptions title="基本信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="申请编号">{{ detail.applyNumber || '无' }}</el-descriptions-item>
        <el-descriptions-item label="申请金额"
          ><span v-format="'#,##0.00'">{{ detail.applyAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="审核状态">{{ detail.verifyState || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开票状态">{{ detail.billState || '无' }}</el-descriptions-item>
        <el-descriptions-item label="配送状态">{{ detail.distributionState || '无' }}</el-descriptions-item>
        <el-descriptions-item label="退汇金额"
          ><span v-format="'#,##0.00'">{{ totalReturn || 0 }}</span> 元
          <el-button type="text" style="padding: 0" @click="$refs.Returns.open(returnList)">明细</el-button></el-descriptions-item
        >
        <el-descriptions-item label="开票金额"
          ><span v-format="'#,##0.00'">{{ (detail.applyAmount || 0) - (totalReturn || 0) }}</span> 元</el-descriptions-item
        >
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="发票类型" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="发票类型">{{ detail.invoiceType || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="发票抬头" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="抬头名称">{{ detail.riseName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="纳税人识别号">{{ detail.taxPayerNumber || '无' }}</el-descriptions-item>
        <el-descriptions-item label="单位地址">{{ detail.unitAddress || '无' }}</el-descriptions-item>
        <el-descriptions-item label="电话号码">{{ detail.phone || '无' }}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{ detail.openBank || '无' }}</el-descriptions-item>
        <el-descriptions-item label="银行账户">{{ detail.bankAccount || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="发票内容" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="开票税目" :span="2">{{ detail.invoiceTax || '无' }}</el-descriptions-item>
        <el-descriptions-item label="本次开票金额"
          ><span v-format="'#,##0.00'">{{ detail.invoicedAmount || 0 }}</span> 元</el-descriptions-item
        >
        <el-descriptions-item label="合并笔数">{{ detail.invoicingSource == 2 ? '' : detail.planCount || '无' }}</el-descriptions-item>
        <el-descriptions-item label="备注" :span="2">{{ detail.remark || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="收件人信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="收件人姓名">{{ detail.inboxName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="收件人电话">{{ detail.inboxPhone || '无' }}</el-descriptions-item>
        <el-descriptions-item label="选择区域">{{ detail.inboxArea || '无' }}</el-descriptions-item>
        <el-descriptions-item label="详细地址">{{ detail.inboxAddress || '无' }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{ detail.inboxEmail || '无' }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="快递信息" :column="2" :labelStyle="{ width: '120px', textAlign: 'right', flexShrink: 0 }">
        <el-descriptions-item label="快递名称">{{ detail.expressName || '无' }}</el-descriptions-item>
        <el-descriptions-item label="快递单号">{{ detail.expressNo || '无' }}</el-descriptions-item>
        <el-descriptions-item label="快递附件">
          <ImageViewer v-if="detail.filePath" :path="detail.filePath" />
          <span v-else>无</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-show="tabName == 'plan'">
      <el-table :data="workPlanBillInfos" style="width: 100%">
        <el-table-column type="index" width="50" label="#"> </el-table-column>
        <el-table-column v-if="detail.invoicingSource == 1" prop="customerOrderNo" label="订单编号"> </el-table-column>
        <el-table-column prop="planNo" label="结算单号"> </el-table-column>
        <el-table-column prop="settleAmount" label="支付金额"
          ><span slot-scope="{ row }" v-format="'#,##0.00'">{{ row.settleAmount || 0 }}</span></el-table-column
        >
        <el-table-column prop="taskNo" label="任务编号"> </el-table-column>
        <el-table-column prop="taskName" label="任务名称"> </el-table-column>
      </el-table>
    </div>
    <div v-show="tabName == 'bill'">
      <el-table :data="billDetails" style="width: 100%">
        <el-table-column type="index" width="50" label="#"> </el-table-column>
        <el-table-column prop="receiptNo" label="发票编号"> </el-table-column>
        <el-table-column prop="receiptAmount" label="已开票金额"
          ><span slot-scope="{ row }" v-format="'#,##0.00'">{{ row.receiptAmount || 0 }}</span></el-table-column
        >
        <el-table-column label="发票图片">
          <template slot-scope="{ row }">
            <ImageViewer v-if="row.receiptFilePath && isImage(row.type)" :path="row.receiptFilePath" />
            <span @click="viewPdf(row.receiptFilePath)" class="otherStyle" v-else>查看文件</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Returns ref="Returns"></Returns>
  </DetailView>
</template>

<script>
import ImageViewer from '@/components/tableView/imageViewer'
import Returns from './returns'
import { downloadUrl } from '@/utils'
export default {
  components: { ImageViewer, Returns },
  data() {
    return {
      id: null,
      show: false,
      tabName: 'detail',
      detail: {},
      billDetails: [],
      workPlanBillInfos: [],
      returnList: []
    }
  },
  computed: {
    tabs() {
      return this.detail.invoicingSource == 2
        ? [
            {
              id: 'detail',
              label: '发票信息'
            },
            {
              id: 'bill',
              label: `发票列表${this.billDetails.length ? '（' + this.billDetails.length + '）' : ''}`
            }
          ]
        : [
            {
              id: 'detail',
              label: '发票信息'
            },
            {
              id: 'plan',
              label: `结算${this.detail.invoicingSource == 1 ? '明细' : '单'}列表${
                this.workPlanBillInfos.length ? '（' + this.workPlanBillInfos.length + '）' : ''
              }`
            },
            {
              id: 'bill',
              label: `发票列表${this.billDetails.length ? '（' + this.billDetails.length + '）' : ''}`
            }
          ]
    },
    totalReturn() {
      return this.returnList.reduce((a, b) => {
        return (
          a +
          (parseFloat(b.afterTaxAmount || 0) + parseFloat(b.serviceFee || 0) + parseFloat(b.serviceDiffFee || 0) - parseFloat(b.deductionAmount || 0))
        )
      }, 0)
    }
  },
  methods: {
    // 数据清空
    clear() {
      this.detail = {}
      this.billDetails = []
      this.workPlanBillInfos = []
      this.tabName = 'detail'
    },
    open(obj) {
      this.clear()
      if (obj) {
        console.log(obj)
        this.detail = obj
        this.id = obj.id
        this.show = true
        this.getReturnList()
        if (this.detail.invoicingSource == 1) {
          this.GetInvoiceDetailWorkDetailPageList()
        } else {
          this.GetInvoiceDetailPlanPageList()
        }
        this.GetInvoiceDetailReceiptPageList()
        // 编辑
        // this.id = obj.billId
        // this.show = true
        // this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 判断是否为图片，不区分大小写
    isImage(type) {
      return ['jpg', 'jpeg', 'png', 'gif'].includes(type.toLowerCase())
    },
    viewPdf(e) {
      downloadUrl(e)
    },
    async GetInvoiceDetailPlanPageList() {
      const res = await this.$api.GetInvoiceDetailPlanPageList({
        InvoiceId: this.id,
        PageSize: 1000
      })
      this.workPlanBillInfos = res.list
    },
    async GetInvoiceDetailWorkDetailPageList() {
      const res = await this.$api.GetInvoiceDetailWorkDetailPageList({
        InvoiceId: this.id,
        PageSize: 1000
      })
      this.workPlanBillInfos = res.list
    },
    async GetInvoiceDetailReceiptPageList() {
      const res = await this.$api.GetInvoiceDetailReceiptPageList({
        InvoiceId: this.id,
        PageSize: 1000
      })
      res.list.map((item) => {
        let i = item?.receiptFilePath.lastIndexOf('.')
        item.type = item?.receiptFilePath.substr(i + 1)
      })
      this.billDetails = res.list
    },
    // 获取发票的退汇明细
    async getReturnList() {
      const res = await this.$api.GetReturnWorkDetailByInvoiceId({ InvoiceId: this.id })
      this.returnList = res.list || []
    }
  }
}
</script>
<style lang="scss" scoped>
.otherStyle {
  color: #23d38a;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
</style>
